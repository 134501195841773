.SelectSearch {


  &__dropdown {
    // @extend %select_dropdown;
    @extend %glasses_effect;
    min-width: 25rem !important;
    margin-top: 20px;
    //@mixin glassesEffect($opacity, $blur) {
    //}
    border-radius: 2.1rem;
    padding: 0;
    .ant-select-item {
      //padding: 1rem;
      font-size: 1.4rem;
      padding: 1.8rem;
    }
    .ant-select-item-option-active {
      background: var(--color-zircon);
    }
  
  }
}