.DhcpStatics {
  display: flex;
  flex-direction: column;
  .btn__dhcpStatic__add {
    margin-top: 2rem;
  }
  p {
    font-weight: 600;
    position: relative;
    padding-bottom: 1rem;
    margin-top: 5rem;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background: var(--color-mercury);
      left: 0;
      bottom: 0;
    }
  }
}
