@import "../../../../../../../styles/mixin.scss";

.Arp {
    margin-top: 3rem;
    padding-left: 10%;
    padding-right: 10%;
    & > div:nth-child(1) {
        //background: red;
        margin-bottom: 0 !important;
        padding-bottom: 1rem;
        border-bottom: 1px solid #417bf6;

        h3 {
            color: #417bf6;
            font-weight: 500;
            font-family: "Poppins";
            font-size: medium;
        }
    }

    div {
        padding-left: 10%;
        padding-right: 10%;
        p {
            font-family: "Poppins";
            font-weight: 400;
            font-size: small;
            // color: #417bf6;
        }
    }
}
