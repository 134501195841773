.DhcpStatic {
  border-bottom: 1px solid var(--color-mercury);
  padding-top: 3rem;
  .ctn__btns {
    display: flex !important;
    justify-content: flex-end !important;
    button {
      margin-left: 2rem;
    }
  }
}
