.Setting {

  .actions__labo {
    display: flex;
    justify-content: space-between;

  }

  .export__logs__btns {
    display: flex;
    margin: auto 0;
    button {
      border: 1px solid var(--color-dodger-blue);
      color: var(--color-dodger-blue);
      border-radius: 3vw;
      height: 1.7vw;
      padding: 0 1vw;
      margin-left: 1vw;

    }
  }
  .ctn-inputs-date {
    display: flex;

    label {
      font-size: var(--font-size-medium);
      font-weight: 500;
      margin-bottom: 1rem;
    }

    .ctn-input-date {
      display: flex;
      flex-direction: column;
      margin-right: 3rem;
    }

    .ant-picker {
      border-radius: 30rem;
    }

    input {
      font-size: 1.4rem;
    }
  }

  .submit__group {
    position: fixed;
    right: 2vw;
    bottom: 2vw;
    background: none;
    border-radius: 25vw !important;
    border: 1px solid var(--color-dodger-blue);
    color: var(--color-dodger-blue) !important;
    font-size: var(--font-size-xs) !important;
    height: unset;
    padding: var(--spacing-10) var(--spacing-15);
  }
  .objects {
    padding-bottom: 1vw;

    .btn__entry__update {
      background: none;
      border-radius: 25vw !important;
      border: 1px solid var(--color-dodger-blue);
      color: var(--color-dodger-blue) !important;
      font-size: var(--font-size-xs) !important;
      height: unset;
      padding: var(--spacing-10) var(--spacing-15);
    }
    border: 1px solid #d9d9d9;
    width: 29vw;
    border-radius: 1vw;

    label {
      color: #757d85;
    }
    .icon__cross__remove {
      &::before, &::after {
        background: var(--color-radical-red);
      }
    }
    &__title {
      padding: 1vw;
      font-weight: bold;
      h2 {
        font-size: var(--font-size-xl);
      }
    }
    .ctn__fields {
      padding: 1vw;
      div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.4vw;
      }
    }
    .ctn__details {

      label {;
        margin-top: 1.2vw;
      }
    }
    .ctn__btns {
      button {
        background: none;
        border-radius: 25vw !important;
        border: 1px solid var(--color-dodger-blue);
        color: var(--color-dodger-blue) !important;
        font-size: var(--font-size-xs) !important;
        height: unset;
        margin: 0;
        padding: var(--spacing-10) var(--spacing-15);
        margin-left: 1vw;
      }

      button:last-child {
        border: 1px solid var(--color-radical-red);
        color: var(--color-radical-red) !important;
      }
    }
  }
}