.dual-button {
    display: flex;
    justify-content: space-between;
    border: 1px solid #e6f0fe;
    width: 12rem;
    height: 4rem;
    border-radius: 20px;
    overflow: hidden;
    font-family: "Poppins";
}

.dual-button button {
    flex: 1;
    border: none;
    background-color: #fff;
    cursor: pointer;
}

.dual-button button.selected {
    background-color: #e6f0fe;
    color: #417bf6;
}

.dual-button-down {
    display: flex;
    justify-content: space-between;
    border: 1px solid #e6f0fe;
    width: 12rem;
    height: 4rem;
    border-radius: 20px;
    overflow: hidden;
    font-family: "Poppins";
}

.dual-button-down button {
    flex: 1;
    border: none;
    background-color: #fff;
    cursor: pointer;
}

.dual-button-down button.selected {
    background-color: #ff304a;
    color: white;
}
