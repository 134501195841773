
.SelectSimple {
  font-size: 1.4rem;
  height: 4.4rem;
  min-width: 31.5rem;

  .ant-select-selector {
    border-radius: 0.7rem !important;
    display: flex;
  }

  .ant-select-selection-item {
    margin: auto 0!important;

    align-items: center;
    display: flex;
    line-height: inherit !important;
    vertical-align: middle;

  }

  &__dropdown {
    @extend %select_dropdown;
  }

}

