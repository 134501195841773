.bloc {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: var(--spacing-30);
  grid-row-gap: var(--spacing-30);
  grid-template-areas: "internet internet";
}

.Banner {
  background: url("../../../../assets/images/BANDEAU.png");
  background-size: 100% 100%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  height: 9vw;
  padding: var(--spacing-20) 2rem;

  .sub__ctn2 {
    font-weight: 500;
    color: white;
    // width: 45%;
    text-align: center;
    display: flex !important;
    flex-direction: column;
    font-size: var(--font-size-medium);
    line-height: 10px;
    margin-bottom: 2rem;
    font-weight: 600;
    height: 100%;

    p {
      color: white;
    }

    .ctn__img2 {
      height: 75%;
      display: flex;
    }

    .order_7 {
      animation-delay: 1800ms;
    }

    .order_8 {
      animation-delay: 2100ms;
    }

    img {
      height: 100%;
      width: auto;
      margin: auto;
      //animation: dropIn 5s ease-in-out infinite;
      // animation: float 5s ease-in-out infinite;
      // cubic-bezier(.29, 1.01, 1, -0.68)
      //cubic-bezier(.08, .96, .9, -0.18)
    }
  }

  .sub__ctn {
    //@import "./animation.scss";
    font-weight: 500;
    color: white;
    width: 50%;
    text-align: center;
    display: flex !important;
    flex-direction: column;
    font-size: var(--font-size-medium);
    line-height: 13px;
    margin-bottom: 2rem;
    font-weight: 600;
    height: 100%;

    .ctn__img {
      height: 65%;
      display: flex;
    }

    .order_1 {
      animation-delay: 0ms;
    }

    .order_2 {
      animation-delay: 300ms;
    }

    .order_3 {
      animation-delay: 600ms;
    }

    .order_4 {
      animation-delay: 900ms;
    }

    .order_5 {
      animation-delay: 1200ms;
    }

    .order_6 {
      animation-delay: 1500ms;
    }

    img {
      height: 80%;
      width: auto;
      margin: auto;
      animation: dropIn 5s ease-in-out infinite;
      // animation: float 5s ease-in-out infinite;
      // cubic-bezier(.29, 1.01, 1, -0.68)
      //cubic-bezier(.08, .96, .9, -0.18)
    }

    p {
      color: white;
      margin-top: auto;
      font-size: var(--font-size-medium) !important;
      line-height: var(--font-size-large);
    }
  }

  .loading {
    color: white;
    font-size: 1.5rem;
  }


}