.InputNameSwitch {
    @keyframes blinkAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    .blink {
        animation: blinkAnimation 1.5s infinite;
    }

    @extend %position_vertical_center;
    display: flex;
    justify-content: space-between;
    left: 8.4rem;
    position: absolute;
    width: calc(100% - 6.4rem - 3rem);
    .ant-switch {
        margin: auto 0px;
    }

    div {
        margin: auto 0;
    }
    .DropdownCollapse {
        background-color: transparent;
        box-shadow: none;
    }
    .numberInterface {
        font-weight: 500;
        font-size: small;
        background-color: #e6f0fe;
        color: #417bf6;
        margin-left: -3rem;
        margin-right: 2rem;
        margin-top: 0.3rem;
        padding-top: 2px;
        padding-right: 1px;
    }
    button {
        border: none;
        outline: none;
        margin-right: 1rem;
        .threepoints {
            height: 0.5rem;
            fill: var(--color-gray);
        }
    }
    .TextField {
        width: 22rem;
    }
}

.dropdown__inputNameSwitch {
    background: red;
    @extend %glasses_effect;
    @extend %light_shadow;
    border-radius: 0.8vw;
    padding-top: 0 !important;
    @extend %flex_column;

    button {
        color: var(--color-gray);
        font-weight: 500;
        min-width: 14rem;
        padding: 1rem;
        font-size: var(--font-size-medium);
        &:hover {
            background: var(--color-zircon);
            transition: all 200ms;
        }
    }
}
