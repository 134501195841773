@import '../../styles/mixin';

body .ReactTable {
  cursor: pointer;
  // padding: 10rem;
  
  .pagination-bottom {
    position: absolute;
    right: 0;
    bottom: -20rem;
    font-size: var(--font-size-large);
    padding-bottom: 15rem;

    .-pagination {
      display: grid;
      grid-template-areas: "center previous next";
      background-color: transparent;
      border: none;
      box-shadow: none;
      grid-gap: 0.7vw;
      color: var(--color-gray);

      .-center {
        grid-area: center;
        display: flex;

        flex-direction: row-reverse;

        .-pageSizeOptions {
          position: relative;
          z-index: 1;
          margin-right: 1vw !important;

          select {
            //   box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
            //   padding: 0.1vw 0.2vw !important;
            background: transparent !important;
          }
        }

        .-pageInfo {
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          position: relative;
          margin-left: -1.7vw;
          margin-right: 0;
          background: transparent !important;

          &:before {
            position: absolute;
            content: "";
            width: 3vw;
            height: 100%;
            background-color: #fcfcfc;
          }

          &:after {
            content: "sur";
            position: relative;
            left: -4.7vw;
            padding-left: 3rem;
            margin-right: 0.5vw;
            background-color: #fcfcfc;
            //width: 2vw;
          }
        }
      }

      .-previous {
        grid-area: previous;
        display: flex;

        &>.-btn {
          transform: scaleX(-1) !important;
          background: url("../../assets/images/fleche-gris.svg") no-repeat !important;
        }
      }

      .-next {
        grid-area: next;
        display: flex;

        &>.-btn {
          background: url("../../assets/images/fleche-gris.svg") no-repeat !important;
        }
      }

      .-next>.-btn,
      .-previous>.-btn {
        overflow: hidden;
        background-size: 0.3vw !important;
        background-position-x: 55% !important;
        background-position-y: center !important;
        margin-top: auto;
        margin-bottom: auto;
        width: var(--font-size-xl) !important;
        height: var(--font-size-xl) !important;
        //height: var(--font-size-m) !important;
        text-indent: -9999px;
        border-radius: 50%;
        //width: 10px;
      }

      .-next>.-btn:hover,
      .-previous>.-btn:hover {
        background-color: var(--color-solitude) !important;
        border-radius: 50%;
      }


      .-previous .-btn[disabled] {
        background-color: rgba($color: #000000, $alpha: 0) !important;
      }

      select,
      input {
        padding: 0 !important;
        border: none;
      }

      .-pageJump {
        input {
          width: 2vw;
          // padding: 0.1vw 0.2vw !important;
          // box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
          z-index: 2;
          position: relative;
          margin-right: 1vw;
          padding-left: 0.8vw !important;
          background-color: transparent;
          color: var(--color-gray); 
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type=number] {
          -moz-appearance: textfield;
        }

      }

      .-totalPages {
        margin-left: 1.8vw;
      }
    }
  }
}

.BasicTable {
  position: relative;


  .Information,
  .operationnal,
  .Opérationnel {
    background: var(--color-caribbean-green);
  }

  .Dégradé,
  .degraded {
    background: var(--color-salmon);
  }

  .offline,
  .Hors,
  .Critique {
    background: var(--color-radical-red);
  }

  .cell__state {
    @extend %position_center;
    border-radius: 0.8rem;
    color: var(--color-white);
    padding: 1rem 0;
    position: absolute;
    text-align: center;
    width: 100%;
  }

  .ctn__table__title {
    display: flex;

    button {
      display: flex;
    }

    svg {
      height: 1.4rem;
      margin-bottom: auto;
      margin-left: 0.6rem;
      margin-top: auto;
      color: var(--color-dodger-blue)
    }
  }

  .-header {
    background: var(--color-solitude);
    border-top-left-radius: 2.1rem;
    border-top-right-radius: 2.1rem;
    color: var(--color-dodger-blue);
    font-weight: 600;
    padding-bottom: 1.6rem;
    padding-top: 3.6rem;

  }

  .-filters {
    border-bottom: 0.1rem solid var(--color-mercury);
    border-top: 0.1rem solid var(--color-mercury);
    padding-left: 4.15rem;
    padding-right: 4.15rem;

    .rt-th {
      padding: 0.8rem 0;
      text-align: center;

      input {
        border-radius: 0.7rem;
        border: 0.1rem solid var(--color-silver);
        height: 3.6rem;
        text-align: center;
        width: 12.5rem;
      }
    }
  }

  .rt-resizable-header-content,
  .rt-td {
    display: flex;
    justify-content: center;

  }

  .rt-tr {

    .rt-th:first-child,
    .rt-td:first-child {
      display: flex;
      justify-content: flex-start !important;
    }

    .rt-th:last-child,
    .rt-td:last-child {
      display: flex;
      justify-content: flex-end !important;
    }
  }


  .rt-tr-group {
    &:not(:last-child) {
      border-bottom: 0.1rem solid var(--color-mercury);
    }

    font-weight: 500;

    &:hover {
      background: var(--color-milk);
    }
  }

  .rt-tr {
    display: flex;

  }

  .rt-table {
    @extend %light_shadow;
    background: var(--color-white);
    border-radius: 2.1rem;
    font-size: 1.4rem;
    margin-top: 2.6rem;
  }

  .-header,
  .rt-tbody {
    .rt-tr {
      padding-left: 4.15rem;
      padding-right: 4.15rem;
    }
  }

  .rt-tbody {
    .rt-tr {
      display: flex;
      height: 5.5rem;

      .rt-td {
        color: var(--color-woodsmoke);
        margin: auto;
        position: relative;
        text-align: center;
      }
    }
  }


  .-loading-inner {
    display: none;
  }
}

.BasicTable__checkbox {

  .rt-resizable-header-content,
  .rt-td {
    display: flex;
    justify-content: center;

  }

  .rt-tr {

    .rt-th:nth-child(2),
    .rt-td:nth-child(2) {
      display: flex;
      justify-content: flex-start !important;
    }

  }


}

.rt-noData {
  left: 50%;
  position: absolute;
  top: calc(50% + 6rem);
  transform: translate(-50%, -50%);
  margin-top: 30vh;
  img {
    height: 13rem;
    width: auto;
  }

  p {
    text-align: center;
  }
}